<template>
  <div class="col-auto">
    <Button color="green_light" width="100" btnText="Next" icon="arrow" @buttonClicked="goNext" :disabled="nextDisabled">
      <IconArrowForward color="white" />
    </Button>
  </div>
</template>
<script>

import { defineAsyncComponent } from 'vue'
export default {
  name: 'NextButton',
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  props: ['nextDisabled'],
  emits: ['goNext'],
  methods: {
    goNext () {
      this.$emit('goNext')
    }
  }
}
</script>
